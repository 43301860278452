import Input from "../../components/Input";
import React, { useEffect, useState, useRef } from "react";

interface IProps {
  options: {
    label: string;
    value: string | number;
  }[];
  onChange: (value: string) => void;
  className?: string;
  style?: React.CSSProperties;
  value: string;
  onClick?: (value: string) => void;
}
const SelectInput: React.FC<IProps> = ({
  className,
  options,
  style,
  value,
  onChange,
  onClick = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOutsideClick = (event: any) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div
      ref={selectRef}
      className={["flex flex-col cursor-pointer", className].join(" ")}
      style={style}
    >
      <div className={[`relative w-full`].join(" ")}>
        <div onClick={toggleDropdown}>
          <Input
            type="text"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            placeholder={value ? "not selected" : "Select an option"}
            className=""
          />
        </div>

        {isOpen && (
          <ul className="mb-0 list-none rounded-b-md absolute bg-field border border-gray w-full max-h-[270px] overflow-y-auto p-0 z-20 mt-0">
            {options
              .filter((item) => item.label !== value)
              .map((item, index) => {
                return (
                  <li
                    key={index}
                    className={`text-black font-helvetica font-bold px-4 py-2 border-b-gray border-b-[1px] ${
                      index === options.length - 1
                        ? "border-b-0"
                        : "border-b-[1px]"
                    }`}
                    role="menuitem"
                    onClick={() => {
                      onChange(item.label);
                      toggleDropdown();
                      onClick(item.label);
                    }}
                  >
                    {item.label}
                  </li>
                );
              })}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SelectInput;
