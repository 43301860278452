import { useState, useCallback, useMemo, useEffect, Fragment } from "react";
import DataLoaderOverlay from "components/DataLoaderOverlay";
import { useParams, useSearchParams } from "react-router-dom";
import { usePaymentAndUpload } from "hooks/usePaymentAndUpload";
import { useUser } from "state/user/hooks";


type IProp = {
  isLoading: boolean;
}

const Order = ({isLoading}: IProp) => {
  const { getOrderById, generatePaymentLink, orderKit, checkPayment } = usePaymentAndUpload();
  const { orderId } = useParams<{ orderId: string }>();
  const [order, setOrder] = useState<any | null>(null);
  const [payment_link, setPaymentLink] = useState("");
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  const [searchParams, setSearchParams] = useSearchParams();
  const queryParamSuccess = searchParams.get('success');
  const queryParamCanceled = searchParams.get('canceled');
  const queryParamSessionId = searchParams.get('sessionId');
  const { user } = useUser();
  const isSuperAdmin = user?.is_SuperAdmin;

  const handleKitOrder = async () => {
    if (order?.status == "Paid") {
      let response = await orderKit({orderId: order?.id, action:"order_kit"});
      if (response?.success && orderId) {
        console.log("Kit order response: ", response);
        setOrder(await getOrderById(orderId));
      }
    }
  }
  const handleKitRegistration = async () => {
    if (order?.status == "Kit Id Generated") {
      let response = await orderKit({orderId: order?.id, action:"register_kit"});
      if (response?.success && orderId) {
        console.log("Kit order response: ", response);
        setOrder(await getOrderById(orderId));
      }
    }
  }

  // Process a callback url and handle success or cancellation
  useEffect(() => {
    (async () => {
      if (queryParamSuccess && queryParamSessionId && orderId) {
        console.log(queryParamSessionId);
        let response = await checkPayment({"paymentSessionsID": queryParamSessionId, "orderId": orderId, "action": "check_payment"});
        if (response?.success) {
          setSearchParams({});
        }
      }
      if (orderId) {
        setOrder(await getOrderById(orderId));
      }
      if (order?.status == "Draft" && orderId) {
        setPaymentLink(await generatePaymentLink(orderId));
      }
    })();
  }, [order?.status])

  return (
    <DataLoaderOverlay isLoading={isLoading}>
      <div className="pt-14 bg-main">
        <div className="flex flex-col justify-center">
          <div className="px-[40px] w-full">
            <div className="overflow-x-auto w-full sm:w-9/12 mx-auto">
              <h1 className="text-4xl">Order: {orderId}</h1>
              <div className="bg-[#FCFCFC] p-8 my-8">
                <div className="flex flex-row pb-6">
                  <div className="basis-1/6 pl-6">
                    <h2 className="text-2xl">Client</h2>
                  </div>
                  <div className="basis-5/6 font-helvetica">
                    <p className="text-xl mb-2">{order?.attention}</p>
                    <p>{order?.address_line_1}</p>
                    <p>{order?.address_line_2}</p>
                    <p>{order?.city}</p>
                    <p>{order?.state}</p>
                    <p>{order?.province}</p>
                    <p>{order?.postal_code}</p>
                    <p>{order?.zip_code}</p>
                    <p><b>{order?.country}</b></p>
                  </div>
                </div>
                <div className="flex flex-row pb-6">
                  <div className="basis-1/6 pl-6">
                    <h2 className="text-2xl">Product</h2>
                  </div>
                  <div className="basis-5/6 font-helvetica">
                    <p className="text-xl mb-2">{order?.kit_orders[0].product.name}</p>
                    <p>{order?.kit_orders[0].product.description}</p>
                    <p>Price: ${order?.kit_orders[0].product.display_price}</p>
                    <p>Shipping Cost: ${order?.shipping_cost}</p>
                  </div>
                </div>
                <div className="flex flex-row pb-6">
                  <div className="basis-1/6 pl-6">
                    <h2 className="text-2xl">Order</h2>
                  </div>
                  <div className="basis-5/6 font-helvetica">
                    <p>Status: {order?.status}</p>
                    <p>Date created: {new Date(Date.parse(order?.date_created)).toLocaleDateString('en-US', options)}</p>
                    <p>Kit status: {order?.kit_orders[0].kit_status ? order?.kit_orders[0].kit_status : "N/A"}</p>
                    {payment_link &&
                      <p><a href={payment_link} className="underline">Payment Link</a></p>
                    }
                  </div>
                </div>
                {isSuperAdmin &&
                  <div className="flex flex-row pb-2">
                    <ActionButton value="Order Kit" status={order?.status == "Paid"} callback={handleKitOrder}/>
                    <ActionButton value="Register Kit" status={order?.status == "Kit Id Generated"} callback={handleKitRegistration}/>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </DataLoaderOverlay> 
    )
}

const ActionButton = ({value, status, callback}: { value: string, status: boolean, callback: () => void }) => {
  return (
    <button onClick={callback} className={(status
      ? "bg-orange-200 cursor-pointer" 
      : "bg-zinc-300 cursor-not-allowed") 
      + " py-3 px-4 rounded-md px-[30px] text-xl max-w-[200px] mr-4"}>{value}</button>
  );
}

export default Order
