import { useEffect } from "react";
import { useOrderKitsData } from "hooks/useOrderKits";
import DataLoaderOverlay from "components/DataLoaderOverlay";
import { Routes, Route } from "react-router";
import { Link } from "react-router-dom";
import Checkout from "./components/Checkout";
import Order from "./components/Order";
import { usePaymentAndUpload } from "hooks/usePaymentAndUpload";


// const Order = () => {
//   const [message, setMessage] = useState("");
//   const [showShippingForm, setShowShippingForm] = useState(false);
//   const [sessionId, setSessionId] = useState("");

//   useEffect(() => {
//     // Check to see if this is a redirect back from Checkout
//     const query = new URLSearchParams(window.location.search);

//     if (query.get("success")) {
//       const sessionIdFromQuery = query.get("sessionId")||"";
//       setSessionId(sessionIdFromQuery);

//       fetch(`${process.env.REACT_APP_PUBLIC_BASE_URL}/check-session-status/${sessionIdFromQuery}/`)
//         .then((response) => {
//           if (response.ok) {
//             setShowShippingForm(true);
//             setMessage("Order placed! Please provide your shipping information.");
//           } else {
//             setMessage("Failed to verify session. Please try again.");
//           }
//         })
//         .catch((error) => {
//           console.error("Error checking session status:", error);
//           setMessage("An error occurred while checking session status.");
//         });
//     }

//     if (query.get("canceled")) {
//       setMessage(
//         "Order canceled -- continue to shop around and checkout when you're ready."
//       );
//     }
//   }, []);

//   return (
//     <Fragment>
//       <Message message={message} />
//       <ShippingForm sessionId={sessionId} />
//     </Fragment>     
//   )
// }

const ShowOrders = () => {
  // Fetch orders from Django API
  const { fetchOrderKits, orderKitsData, isLoading } = useOrderKitsData();
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  useEffect(() => {
    fetchOrderKits();
  }, [fetchOrderKits]);

  // Display orders here
  return (
    <DataLoaderOverlay isLoading={isLoading}>
      <div className="pt-14 bg-main">
        <div className="flex flex-col justify-center">
          <div className="px-[40px] w-full">
            <div className="overflow-x-auto w-full sm:w-9/12 mx-auto">
              <div className="flex flex-row">
                <div className="basis-1/2"><h1 className="text-4xl">Orders</h1></div>
                <div className="basis-1/2">
                  <Link to="/orders/checkout" className="py-3 px-4 rounded-md px-[30px] text-xl bg-white max-w-[200px] float-right">+ New Order</Link>
                </div>
              </div>
              <div className="bg-[#FCFCFC] pt-8 my-8">
                <div className="flex flex-row text-xl pb-6 italic">
                  <div className="basis-1/3 pl-9">Client Name</div>
                  <div className="basis-1/3">Status</div>
                  <div className="basis-1/3">Date Created</div>
                </div>
                {orderKitsData && orderKitsData.map((order, i) => (
                  <div className="flex flex-row py-4 border-t font-helvetica" key={i}>
                    <div className="basis-1/3 pl-9 underline"><Link to={"/orders/" + order.id}>{order.attention}</Link></div>
                    <div className="basis-1/3">{order.status}</div>
                    <div className="basis-1/3">{new Date(Date.parse(order.date_created)).toLocaleDateString('en-US', options)}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </DataLoaderOverlay>
  );
}

export default function Orders() {
  const { isLoading, processCheckoutOnly, getUserData, getProducts } =
    usePaymentAndUpload();
  return (
    <Routes>
      <Route index element={<ShowOrders />} />
      <Route path="/checkout" element={<Checkout 
        isLoading={isLoading}
        processCheckoutOnly={processCheckoutOnly}
        getUserData={getUserData}
        getProducts={getProducts}
        />} 
      />
      <Route path="/:orderId" element={<Order isLoading={isLoading}/>} />
    </Routes>
  );
}
