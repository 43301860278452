import { useState, useCallback, useMemo, useEffect } from "react";
import Input from "components/Input";
import Button from "components/Button";
import Select from "components/Select";
import CheckboxGroup from "../../../components/RadioButton/RadioButton";

import { useFormik } from "formik";
import { FormOptions } from "types";
import { CHECKOUTVALUES, FORMOPTIONS, COUNTRIES } from "constants/index";

import { checkoutSchema } from "utils/validationSchema";
import DataLoaderOverlay from "components/DataLoaderOverlay";

type IProps = {
  isLoading: boolean,
  getUserData: () => void;
  processCheckoutOnly: (arg: any) => void;
  getProducts: () => Promise<any>;
};
const Checkout = ({ isLoading, processCheckoutOnly, getUserData, getProducts }: IProps) => {
  const [open, setOpen] = useState(false);
  const [selectForm, setSelectForm] = useState<FormOptions>(
    FormOptions["California"]
  );
  const [countryValue, setCountryValue] = useState<any>("United States");
  const [products, setProducts] = useState([]);

  let productOptions:any[] = [];
  
  useEffect(() => {
    (async () => {
      getUserData();
      setProducts(await getProducts());
    })();
  }, []);

  products.forEach((product:any) => {
    productOptions.push({ label: product.name + " $" + product.display_price, value: product.id.toString() })
  })

  const COUNTRIES_OPTIONS = COUNTRIES.map((country: any) => ({
    value: country.name,
    label: country.name,
  }));

  const { handleSubmit, handleChange, values, touched, errors, setFieldValue } =
    useFormik({
      enableReinitialize: true,
      initialValues: { ...CHECKOUTVALUES, product: productOptions[0]?.value },
      validationSchema: checkoutSchema,
      onSubmit: async () => {
        //setOpen(true);
        let response: any = await processCheckoutOnly({
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          shipping_name: values.firstName + " " + values.lastName,
          shipping_address_line_1: values.shippingAddress1,
          shipping_address_line_2: values.shippingAddress2,
          city: values.city,
          state: values.state,
          zip_code: values.zipCode,
          product: values.product,
          country: values.country,
          province: values.province,
          postal_code: values.postalCode,
        })
        // Redirect to Stripe for payment
        window.location.href = response.data.url;
      },
    })

  return (
    <DataLoaderOverlay isLoading={isLoading}>
      <div className="pt-14 bg-main font-helvetica">
        <div className="flex flex-col justify-center">
          <div className="overflow-x-auto w-full sm:w-6/12 mx-auto">
            <form
              className={`font-helvetica bg-[#FCFCFC] p-10 rounded md:shadow-md  justify-center items-center ${open && "filter blur"
                }`}
              noValidate
              onSubmit={handleSubmit}
            >
              <h2 className="text-xl mt-5 mb-10 leading-normal">
                Select Product
              </h2>
              <div className="mb-5">
                <CheckboxGroup
                  options={productOptions}
                  onChange={handleChange}
                  name="product"
                  error={touched.product && errors.product?.toString()}
                  value={
                    values.product
                      ? values.product.toString()
                      : values.product
                  }
                  className="font-helvetica"
                />
              </div>
              <h2 className="text-xl mt-7 mb-3 leading-normal">
                Enter Client's Shipping Details
              </h2>
              <p className="text-red-600 py-2">Enter client's physical address where the kit will be delivered. Payment and billing information will be collected on the next page.</p>
              <div>
                <Input
                  type="text"
                  name="firstName"
                  className="mb-4 px-3 font-helvetica mt-2"
                  value={values.firstName}
                  placeholder="First Name"
                  onChange={handleChange}
                  error={touched.firstName && errors.firstName}
                />
                <Input
                  type="text"
                  name="lastName"
                  className="mb-4 px-3 font-helvetica mt-2"
                  value={values.lastName}
                  placeholder="Last Name"
                  onChange={handleChange}
                  error={touched.lastName && errors.lastName}
                />
                <Input
                  type="text"
                  name="email"
                  className="mb-4 px-3 font-helvetica mt-2"
                  value={values.email}
                  placeholder="Client's Email"
                  onChange={handleChange}
                  error={touched.email && errors.email}
                />
                <Select
                  onChange={handleChange}
                  className="mb-2 font-helvetica mt-2"
                  options={COUNTRIES_OPTIONS}
                  value={countryValue}
                  onClick={(value: string) => {
                    setCountryValue(value as string);
                    setFieldValue("country", value);
                  }}
                />
                <Input
                  type="text"
                  name="shippingAddress1"
                  className="mb-4 px-3 font-helvetica mt-2"
                  value={values.shippingAddress1}
                  placeholder="Shipping Address Line 1"
                  onChange={handleChange}
                  error={touched.shippingAddress1 && errors.shippingAddress1}
                />
                <Input
                  type="text"
                  name="shippingAddress2"
                  className="mb-4 px-3 font-helvetica mt-2"
                  value={values.shippingAddress2}
                  placeholder="Shipping Address Line 2"
                  onChange={handleChange}
                  error={touched.shippingAddress2 && errors.shippingAddress2}
                />
                <Input
                  type="text"
                  name="city"
                  className="mb-4 px-3 font-helvetica mt-2"
                  value={values.city}
                  placeholder="City"
                  onChange={handleChange}
                  error={touched.city && errors.city}
                />
                {countryValue == "United States" ? (
                  <>
                    <Select
                      onChange={handleChange}
                      className="mb-2 font-helvetica mt-2"
                      options={FORMOPTIONS}
                      value={selectForm}
                      onClick={(value: string) => {
                        setSelectForm(value as FormOptions);
                        setFieldValue("state", value);
                      }}
                    />
                    <Input
                      type="text"
                      name="zipCode"
                      className="mb-4 px-3 font-helvetica mt-2"
                      value={values.zipCode}
                      placeholder="Zip Code"
                      onChange={handleChange}
                      error={touched.zipCode && errors.zipCode}
                    />
                  </>
                ) : (
                  <>
                    <Input
                      type="text"
                      name="province"
                      className="mb-4 px-3 font-helvetica mt-2"
                      value={values.province}
                      placeholder="State/Province"
                      onChange={handleChange}
                      error={touched.province && errors.province}
                    />
                    <Input
                      type="text"
                      name="postalCode"
                      className="mb-4 px-3 font-helvetica mt-2"
                      value={values.postalCode}
                      placeholder="Postal Code"
                      onChange={handleChange}
                      error={touched.postalCode && errors.postalCode}
                    />
                  </>
                )}
              </div>
              <Button
                title="Continue to Payment"
                isLoading={isLoading}
                className={`text-xl leading-normal`}
                type="submit"
              />
            </form>
          </div>
        </div>
      </div>
    </DataLoaderOverlay>
  );
}
export default Checkout