import ReportTable from "../../components/ReportTable";
import { useEffect, useMemo } from "react";
import DataLoaderOverlay from "components/DataLoaderOverlay";
import { useClientsManager } from "hooks/useClientManager";
import { useUser } from "state/user/hooks";

const Clients = () => {
  const { fetchCoachClients, coachClients, isLoading, setSelectedClient } =
    useClientsManager();
  useEffect(() => {
    setSelectedClient(undefined);
    fetchCoachClients();
  }, [fetchCoachClients, setSelectedClient]);

  const { user } = useUser();
  const isSuperAdmin = user?.is_SuperAdmin;

  const tableData = useMemo(() => {
    return coachClients.map((client) => {
      return {
        col1: isSuperAdmin 
          ? `${client.client_name} - ${client.coach?.first_name} ${client.coach?.last_name}` 
          : client.client_name,
        col2: client.id,
        col3: `${client.coach?.first_name} ${client.coach?.last_name}`,
      };
    });
  }, [coachClients]);

  const filteredDataWithSum = tableData.reduce((acc, current:any, index) => {
    const existingEntry = acc.find((item) => item.col1 === current.col1);

    if (existingEntry) {
      acc.push({ col1: `${existingEntry.col1} (${current.col2})`, col2: current.col2 });
    } else {
      acc.push({ col1: `${current.col1}`, col2: current.col2 });
    }

    return acc;
  }, [] as { col1: string; col2: number }[]);

  return (
    <DataLoaderOverlay isLoading={isLoading}>
      <div className="pt-14 bg-main">
        <div className="flex flex-col justify-center">
          <div className="px-[40px] w-full">
            <ReportTable
              data={filteredDataWithSum}
              setSelectedClient={setSelectedClient}
            />
          </div>
        </div>
      </div>
    </DataLoaderOverlay>
  );
};

export default Clients;
