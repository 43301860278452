import React from "react";
import FileUploadInput from "./components/FileUploadInput";
import { usePaymentAndUpload } from "hooks/usePaymentAndUpload";

const UploadReport = () => {
  const { processPaymentAndUploadData, isLoading, processFilesOnly, credits, getUserData} =
    usePaymentAndUpload();
  return (
    <div className="bg-main">
      <div className="flex flex-col sm:pt-12 mx-4 items-center justify-center pt-2">
        <div className="mt-8">
          <FileUploadInput
            processPaymentAndUploadData={processPaymentAndUploadData}
            isLoading={isLoading}
            credits={credits}
            getUserData={getUserData}
            processFilesOnly={processFilesOnly}
          />
        </div>
      </div>
    </div>
  );
};

export default UploadReport;
