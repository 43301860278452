import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Categories from "pages/Categories";
import Clients from "pages/Clients";
import CategoriesDetail from "pages/CategoriesDetail";
import UploadReport from "pages/UploadReport";
import ClientDetail from "pages/ClientDetail";
import { ProtectedRoutes } from "./ProtectedRoutes";
import { PATHS } from "constants/index";
import ScrollToTop from "hooks/useScrollToTop";
import OrderKits from "pages/OrderKits";
import KitsStatus from "pages/KitsStatus";
import UpdateKitDetails from "pages/UpdateKitDetails";
import LabReportsUpload from "pages/LabReportsUpload";
type IProps = {
  isAdmin: boolean;
};
const Approute = ({ isAdmin }: IProps) => {
  ScrollToTop()
  return (
    <Routes>
      <Route path={PATHS.UPLOADREPORT} element={<UploadReport />} />
      <Route path={PATHS.ORDERKITS} element={<OrderKits />} />
      <Route path={PATHS.UPDATEKITSTATUS} element={<UpdateKitDetails />} />
      <Route path={PATHS.LABREPORTSUPLOAD} element={<LabReportsUpload />} />
      <Route path={PATHS.KITSSTATUS} element={<KitsStatus />} />
      <Route path={`${PATHS.CATEGORIES}/:id`} element={<Categories />} />
      <Route
        path={`${PATHS.CATEGORIES}/:id/:category/:sub_category`}
        element={<CategoriesDetail />}
      />
      <Route path={`${PATHS.CLIENT}/:clientID`} element={<ClientDetail />} />
      <Route path={PATHS.CLIENT} element={<Clients />}/>
      <Route path={PATHS.DEFAULT} element={<Navigate to={PATHS.CLIENT} />} />
    </Routes>
  );
};

export default Approute;
