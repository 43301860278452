import React from "react";
import "./styles.css";
interface Option {
  label: string;
  value: string;
}

type Value = string | [string, number];

interface IProps {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  error: string | boolean | undefined;
  options: Option[]; // Accept an array of Option objects
  name: string;
  value: Value;
  className?: string;
}

const RadioButton: React.FC<IProps> = ({
  onChange,
  error,
  options,
  name,
  value,
  className,
}) => {
  return (
    <div className={"flex flex-col w-full"}>
      {options.map((option, index) => (
        <label
          key={index}
          className="form-control font-helvetica text-lg my-4 space-x-2"
          htmlFor={`${name}-${index}`}
        >
          <input
            type="radio"
            id={`${name}-${index}`}
            name={name}
            value={option.value} // Use the value from the Option object
            checked={
              Array.isArray(value)
                ? option.label === value[0] // If value is a tuple, compare with the label
                : option.value === value // If value is a string, compare directly with the value
            }
            className="mt-[-4px] "
            onChange={onChange}
          />
          {option.label}
        </label>
      ))}
      {error && (
        <p className="text-sm mb-2 font-helvetica text-red-700">{error}</p>
      )}
    </div>
  );
};

export default RadioButton;
