import { combineReducers } from "@reduxjs/toolkit";
import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { persistStore, persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage"; //
import UserReducer from "state/user";
import LoadingReducer from "state/loading";
import RecordsReducer from "state/records";
import ClientsReducer from "state/clients";
import OrderKits from "./orderKits";
const rootReducer = combineReducers({
  user: UserReducer,
  loading: LoadingReducer,
  records: RecordsReducer,
  clients: ClientsReducer,
  orderKits: OrderKits
  // Add other reducers here if you have any
});
const persistConfig = {
  key: "root",
  storage,
  blacklist: ["loading"],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export default store;
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch: () => AppDispatch = useDispatch;
export type AppDispatch = typeof store.dispatch;
